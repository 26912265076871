import _ from 'lodash'

export const initialState = {
    eventLoading: false,
    masterEvent: {
        id: null,
        title: null,
        slug: null,
        logo_url: null,
        background_url: null,
        guideline_url: null,
        can_register: false,
    },
    bannerLoading: false,
    banners: [],
    menuLoading: false,
    menus: [],
    picLoading: false,
    pics: [],
    logLoading: false,
    logs: [],
    pageLoading: false,
    page: {
        id: null, 
        content: null
    },
    pollLoading: false,
    polls: [],
    poll: {
        id: null,
        question: null,
        created_at: null,
    },
    metaPoll: {count: 0},
};

export default function EventReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_EVENT_PROCESS":
            return {...state, eventLoading: true, masterEvent: initialState.masterEvent};
        case "FETCH_EVENT_SUCCESS":
            return {...state, eventLoading: false, masterEvent: action.payload};
        case "FETCH_EVENT_FAILED":
            return {...state, eventLoading: false, masterEvent: initialState.masterEvent};

        case "FETCH_EVENT_LOG_PROCESS":
            return {...state, logLoading: true, logs: initialState.logs};
        case "FETCH_EVENT_LOG_SUCCESS":
            return {...state, logLoading: false, logs: action.payload};
        case "FETCH_EVENT_LOG_FAILED":
            return {...state, logLoading: false, logs: initialState.logs};

        case "FETCH_EVENT_PIC_PROCESS":
            return {...state, picLoading: true, pics: initialState.pics};
        case "FETCH_EVENT_PIC_SUCCESS":
            return {...state, picLoading: false, pics: action.payload};
        case "FETCH_EVENT_PIC_FAILED":
            return {...state, picLoading: false, pics: initialState.pics};

        case "FETCH_BANNER_PROCESS":
            return {...state, bannerLoading: true, banners: initialState.banners};
        case "FETCH_BANNER_SUCCESS":
            return {...state, bannerLoading: false, banners: action.payload};
        case "FETCH_BANNER_FAILED":
            return {...state, bannerLoading: false, banners: initialState.banners};

        case "FETCH_MENU_PROCESS":
            return {...state, menuLoading: true, menus: initialState.menus};
        case "FETCH_MENU_SUCCESS":
            return {...state, menuLoading: false, menus: action.payload};
        case "FETCH_MENU_FAILED":
            return {...state, menuLoading: false, menus: initialState.menus};

        case "VIEW_PAGE_PROCESS":
            return {...state, pageLoading: true, page: initialState.page};
        case "VIEW_PAGE_SUCCESS":
            return {...state, pageLoading: false, page: action.payload};
        case "VIEW_PAGE_FAILED":
            return {...state, pageLoading: false, page: initialState.page};

        case "FETCH_EVENT_POLL_PROCESS":
            return {...state, pollLoading: true, polls: initialState.polls};
        case "FETCH_EVENT_POLL_SUCCESS":
            let pollsResponse = {...state, pollLoading: false}
            return _.isArray(action.payload)
                ? {...pollsResponse, polls: action.payload, metaPoll: action.meta}
                : {...pollsResponse, poll: action.payload}
        case "FETCH_EVENT_POLL_FAILED":
            return {...state, pollLoading: false, polls: initialState.polls};
    
        default:
            return state;
    }

}
