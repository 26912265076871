import _ from 'lodash'

export const initialState = {
    newsLoading: false,
    news: [],
    article: {
        id: null,
        title: null,
        headline_url: null,
        created_at: null,
    }
}

export default function NewsReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_NEWS_PROCESS":
            return {...state, newsLoading: true, news: initialState.news}
        case "FETCH_NEWS_SUCCESS":
            let newsResponse = {...state, newsLoading: false}
            return _.isArray(action.payload) ? {...newsResponse, news: action.payload} : {...newsResponse, article: action.payload}
        case "FETCH_NEWS_FAILED":
            return {...state, newsLoading: false, news: initialState.news}

        default:
            return state
    }
}
