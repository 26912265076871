import _ from 'lodash'

export const initialState = {
    porseniLoading: false,
    cabor: [],
    metaCabor: {count: 0},
    porseni: {
        id: null,
        title: null,
        slug: null,
        tos: null,
    },
    rankLoading: false,
    ranks: [],
    metaRank: {count: 0},
    rankSummaries: {},
    picLoading: false,
    pics: [],
    metaPic: {count: 0},
    scheduleLoading: false,
    schedules: [],
    metaSchedule: {count: 0},
    registerLoading: false,
    registers: [],
    metaRegister: {count: 0},
    teamLoading: false,
    teams: [],
    metaTeam: {count: 0},
    team: {
        id: null,
        name: null,
        manager: null,
        location: null,
    },
    teamMemberLoading: false,
    teamMembers: [],
    metaTeamMember: {count: 0},
    countTeamMemberLoading: false,
    countTeamMember: {count: 0, countAll: 0},
    galleryLoading: false,
    galleries: [],
    metaGallery: {count: 0},
    galleryWinners: [],
    metaGalleryWinner: {count: 0},
}

export default function PorseniReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_PORSENI_PROCESS":
            return {...state, porseniLoading: true, porseni: initialState.porseni}
        case "FETCH_PORSENI_SUCCESS":
            let porseniResponse = {...state, porseniLoading: false}
            return _.isArray(action.payload) ? {...porseniResponse, cabor: action.payload, metaCabor: action.meta} : {...porseniResponse, porseni: action.payload}
        case "FETCH_PORSENI_FAILED":
            return {...state, porseniLoading: false, porseni: initialState.porseni}

        case "FETCH_PORSENI_RANK_PROCESS":
            return {...state, rankLoading: true, ranks: initialState.ranks}
        case "FETCH_PORSENI_RANK_SUCCESS":
            return {...state, rankLoading: false, ranks: action.payload, metaRank: action.meta}
        case "FETCH_PORSENI_RANK_FAILED":
            return {...state, rankLoading: false, ranks: initialState.ranks}

        case "FETCH_PORSENI_RANK_SUMMARY_PROCESS":
            return {...state, rankLoading: true, rankSummaries: initialState.ranks}
        case "FETCH_PORSENI_RANK_SUMMARY_SUCCESS":
            return {...state, rankLoading: false, rankSummaries: action.payload}
        case "FETCH_PORSENI_RANK_SUMMARY_FAILED":
            return {...state, rankLoading: false, rankSummaries: initialState.ranks}

        case "FETCH_PORSENI_SCHEDULE_PROCESS":
            return {...state, scheduleLoading: true, schedules: initialState.schedules}
        case "FETCH_PORSENI_SCHEDULE_SUCCESS":
            return {...state, scheduleLoading: false, schedules: action.payload, metaSchedule: action.meta}
        case "FETCH_PORSENI_SCHEDULE_FAILED":
            return {...state, scheduleLoading: false, schedules: initialState.schedules}

        case "FETCH_PORSENI_PIC_PROCESS":
            return {...state, picLoading: true, pics: initialState.pics}
        case "FETCH_PORSENI_PIC_SUCCESS":
            return {...state, picLoading: false, pics: action.payload, metaPic: action.meta}
        case "FETCH_PORSENI_PIC_FAILED":
            return {...state, picLoading: false, pics: initialState.pics}

        case "FETCH_PORSENI_PRE_REGISTER_PROCESS":
            return {...state, registerLoading: true, registers: initialState.registers}
        case "FETCH_PORSENI_PRE_REGISTER_SUCCESS":
            return {...state, registerLoading: false, registers: action.payload, metaRegister: action.meta}
        case "FETCH_PORSENI_PRE_REGISTER_FAILED":
            return {...state, registerLoading: false, registers: initialState.registers}

        case "FETCH_PORSENI_TEAM_PROCESS":
            return {...state, teamLoading: true, teams: initialState.teams}
        case "FETCH_PORSENI_TEAM_SUCCESS":
            let teamResponse = {...state, teamLoading: false}
            return _.isArray(action.payload) ? {...teamResponse, teams: action.payload, metaTeam: action.meta} : {...teamResponse, team: action.payload}
        case "FETCH_PORSENI_TEAM_FAILED":
            return {...state, teamLoading: false, teams: initialState.teams}

        case "POST_PORSENI_TEAM_PROCESS":
            return {...state, teamLoading: true, team: initialState.team}
        case "POST_PORSENI_TEAM_SUCCESS":
            return {...state, teamLoading: true, team: action.payload}
        case "POST_PORSENI_TEAM_FAILED":
            return {...state, teamLoading: false, team: initialState.team}

        case "PUT_PORSENI_TEAM_PROCESS":
            return {...state, teamLoading: true, team: initialState.team}
        case "PUT_PORSENI_TEAM_SUCCESS":
            return {...state, teamLoading: true, team: action.payload}
        case "PUT_PORSENI_TEAM_FAILED":
            return {...state, teamLoading: false, team: initialState.team}

        case "DELETE_PORSENI_TEAM_PROCESS":
            return {...state, teamLoading: true}
        case "DELETE_PORSENI_TEAM_SUCCESS":
            return {...state, teamLoading: true}
        case "DELETE_PORSENI_TEAM_FAILED":
            return {...state, teamLoading: false}

        case "FETCH_PORSENI_TEAM_MEMBER_PROCESS":
            return {...state, teamMemberLoading: true, teamMembers: initialState.teamMembers}
        case "FETCH_PORSENI_TEAM_MEMBER_SUCCESS":
            return {...state, teamMemberLoading: false, teamMembers: action.payload, metaTeamMember: action.meta}
        case "FETCH_PORSENI_TEAM_MEMBER_FAILED":
            return {...state, teamMemberLoading: false, teamMembers: initialState.teamMembers}

        case "FETCH_PORSENI_TEAM_MEMBER_COUNT_PROCESS":
            return {...state, countTeamMemberLoading: true, countTeamMember: initialState.countTeamMember}
        case "FETCH_PORSENI_TEAM_MEMBER_COUNT_SUCCESS":
            return {...state, countTeamMemberLoading: false, countTeamMember: action.payload}
        case "FETCH_PORSENI_TEAM_MEMBER_COUNT_FAILED":
            return {...state, countTeamMemberLoading: false, countTeamMember: initialState.countTeamMember}

        case "FETCH_PORSENI_GALLERY_WINNER_PROCESS":
        case "FETCH_PORSENI_GALLERY_PROCESS":
            return {...state, galleryLoading: true, galleries: initialState.galleries}
        case "FETCH_PORSENI_GALLERY_WINNER_SUCCESS":
            return {...state, galleryLoading: false, galleryWinners: action.payload, metaGalleryWinner: action.meta}
        case "FETCH_PORSENI_GALLERY_SUCCESS":
            return {...state, galleryLoading: false, galleries: action.payload, metaGallery: action.meta}
        case "FETCH_PORSENI_GALLERY_WINNER_FAILED":
        case "FETCH_PORSENI_GALLERY_FAILED":
            return {...state, galleryLoading: false, galleries: initialState.galleries}

        default:
            return state
    }
}
