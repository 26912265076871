import _ from 'lodash'

export const initialState = {
    schedulesLoading: false,
    schedules: [],
    schedule: {
        id: null,
        title: null,
        slug: null,
        startTime: null,
        endTime: null,
        dueDate: null,
        rating: null,
        photo_url: null,
        created_at: null,
    },
    metaBooth: {count: 0},
    ratingLoading: false,
    ratings: [],
    rating: {
        id: null,
        title: null,
        photo_url: null,
        created_at: null,
    },
    metaRating: {count: 0},
    qnaLoading: false,
    qnas: [],
    qna: {
        id: null,
        question: null,
        created_at: null,
    },
    metaQna: {count: 0},
    qnaReplyLoading: false,
    qnaReplies: [],
    qnaReply: {
        id: null,
        qna_id: null,
        rsvp_id: null,
        content: null,
        created_at: null,
    },
    metaQnaReply: {count: 0},
    qnaLikeLoading: false,
    qnaLikes: [],
    qnaLike: {
        id: null,
        qna_id: null,
        rsvp_id: null,
        created_at: null,
    },
    metaQnaLike: {count: 0},
}

export default function BoothReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_SCHEDULE_PROCESS":
            return {...state, schedulesLoading: true}
        case "FETCH_SCHEDULE_SUCCESS":
            let schedulesResponse = {...state, schedulesLoading: false}
            return _.isArray(action.payload)
                ? {...schedulesResponse, schedules: action.payload, metaBooth: action.meta}
                : {...schedulesResponse, schedule: action.payload}
        case "FETCH_SCHEDULE_FAILED":
            return {...state, schedulesLoading: false, schedules: initialState.schedules}

        case "FETCH_SCHEDULE_RATING_PROCESS":
            return {...state, ratingLoading: true}
        case "FETCH_SCHEDULE_RATING_SUCCESS":
            let ratingsResponse = {...state, ratingLoading: false}
            return _.isArray(action.payload)
                ? {...ratingsResponse, ratings: action.payload, metaRating: action.meta}
                : {...ratingsResponse, rating: action.payload}
        case "FETCH_SCHEDULE_RATING_FAILED":
            return {...state, ratingLoading: false, ratings: initialState.ratings}

        case "FETCH_SCHEDULE_QNA_PROCESS":
            return {...state, qnaLoading: true}
        case "FETCH_SCHEDULE_QNA_SUCCESS":
            let qnasResponse = {...state, qnaLoading: false}
            return _.isArray(action.payload)
                ? {...qnasResponse, qnas: action.payload, metaQna: action.meta}
                : {...qnasResponse, qna: action.payload}
        case "FETCH_SCHEDULE_QNA_FAILED":
            return {...state, qnaLoading: false, qnas: initialState.qnas}

        case "FETCH_SCHEDULE_QNA_REPLY_PROCESS":
            return {...state, qnaReplyLoading: true}
        case "FETCH_SCHEDULE_QNA_REPLY_SUCCESS":
            let qnaRepliesResponse = {...state, qnaReplyLoading: false}
            return _.isArray(action.payload)
                ? {...qnaRepliesResponse, qnaReplies: action.payload, metaQnaReply: action.meta}
                : {...qnaRepliesResponse, qnaReply: action.payload}
        case "FETCH_SCHEDULE_QNA_REPLY_FAILED":
            return {...state, qnaReplyLoading: false, qnaReplies: initialState.qnaReplies}

        case "FETCH_SCHEDULE_QNA_LIKE_PROCESS":
            return {...state, qnaLikeLoading: true}
        case "FETCH_SCHEDULE_QNA_LIKE_SUCCESS":
            let qnaLikesResponse = {...state, qnaLikeLoading: false}
            return _.isArray(action.payload)
                ? {...qnaLikesResponse, qnaLikes: action.payload, metaQnaLike: action.meta}
                : {...qnaLikesResponse, qnaLike: action.payload}
        case "FETCH_SCHEDULE_QNA_LIKE_FAILED":
            return {...state, qnaLikeLoading: false, qnaLikes: initialState.qnaLikes}

        default:
            return state
    }
}
