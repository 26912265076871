import {reducer as formReducer} from 'redux-form';
import AuthReducer from "../components/auth/AuthReducer";
import CommonReducer from "../components/common/CommonReducer";
import UserReducer from "../containers/user/UserReducer";
import EventReducer from "../containers/event/EventReducer";
import NewsReducer from "../containers/news/NewsReducer";
import LivestreamReducer from "../containers/livestream/LivestreamReducer";
import PorseniReducer from "../containers/porseni/PorseniReducer";
import FormReducer from "../containers/form/FormReducer";
import BoothReducer from "../containers/booth/BoothReducer";
import ScheduleReducer from "../containers/schedule/ScheduleReducer";
import QuizReducer from "../containers/quiz/QuizReducer";
import MerchantReducer from "../containers/merchant/MerchantReducer";

export default {
    form: formReducer,
    AuthReducer,
    CommonReducer,
    UserReducer,
    EventReducer,
    NewsReducer,
    LivestreamReducer,
    PorseniReducer,
    FormReducer,
    BoothReducer,
    ScheduleReducer,
    QuizReducer,
    MerchantReducer,
}
