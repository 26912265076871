import _ from 'lodash'

export const initialState = {
    userLoading: false,
    users: [],
    user: {
        id: null,
        username: null,
        email: null,
        name: null,
        phone: null,
        position: null,
        location: null,
        sex: null,
        employee_status: null,
    }
}

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_USER_PROCESS":
            return {...state, userLoading: true, users: initialState.users, user: initialState.user}
        case "FETCH_USER_SUCCESS":
            let userResponse = {...state, userLoading: false}
            return _.isArray(action.payload) ? {...userResponse, users: action.payload} : {...userResponse, user: action.payload}
        case "FETCH_USER_FAILED":
            return {...state, userLoading: false, users: initialState.users, user: initialState.user}

        case "POST_USER_PROCESS":
            return {...state, userLoading: true, user: initialState.user}
        case "POST_USER_SUCCESS":
            return {...state, userLoading: false, user: action.payload}
        case "POST_USER_FAILED":
            return {...state, userLoading: false, user: initialState.user}

        default:
            return state
    }
}
