import _ from 'lodash'

export const initialState = {
    quizCollectionsLoading: false,
    quizCollections: [],
    quizCollection: {
        id: null,
        title: null,
        slug: null,
        quizes: [],
    },
    quizesLoading: false,
    quizesCollections: [],
    quizLoading: false,
    quizPoint: null,
    quiz: {
        id: null,
        event_id: null,
        booth_id: null,
        schedule_id: null,
        question: null,
        options: [],
        answer: null,
        created_at: null,
    }
}

export default function QuizReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_QUIZ_COLLECTION_PROCESS":
            return {...state, quizCollectionsLoading: true, quizCollections: initialState.quizCollections}
        case "FETCH_QUIZ_COLLECTION_SUCCESS":
            let formsResponse = {...state, quizCollectionsLoading: false}
            return _.isArray(action.payload) ? {...formsResponse, quizCollections: action.payload} : {...formsResponse, quizCollection: action.payload}
        case "FETCH_QUIZ_COLLECTION_FAILED":
            return {...state, quizCollectionsLoading: false, quizCollections: initialState.quizCollections}

        case "SUBMIT_QUIZ_PROCESS":
            return {...state, quizLoading: true, quizPoint: initialState.quizPoint}
        case "SUBMIT_QUIZ_SUCCESS":
            return {...state, quizLoading: false, quizPoint: action.payload}
        case "SUBMIT_QUIZ_FAILED":
            return {...state, quizLoading: false, quizPoint: initialState.quizPoint}

        default:
            return state
    }
}
