export const enumerateDaysBetweenDates = function(startDate, endDate, format) {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format(format));
        now.add(1, 'days');
    }
    return dates;
};

export const isEmail = function(string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(string);
}

export const randomNumber = function(max, min) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const randomColor = function() {
    return `rgba(
        ${randomNumber(0, 255)},
        ${randomNumber(0, 255)},
        ${randomNumber(0, 255)}, 
        ${randomNumber(0.1, 1)})`;
}

export const generateUUID = function() { 
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        //eslint-disable-next-line
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const slugify = function(text, separator = '-') {
    return text
        .toString() // Cast to string
        .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, separator) // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, separator) // Replace multiple - with single -
        .replace(new RegExp(`${separator}${separator}`, 'g'), separator); // Replace multiple - with single -
}
