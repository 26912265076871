import _ from 'lodash'
import {Cookies} from 'react-cookie'
import jwt_decode from "jwt-decode"
import Backend, {showErrorNotification} from "../helper/Backend"
import {Form} from "../helper/Form"

export function loggedInUser(email, password, event_id, recaptcha) {
    return dispatch => {
        dispatch({
            type: 'FORM_LOGIN_PROCESS'
        })

        return Backend.post('/v1/account/login', {
            email,
            password,
            event_id,
            recaptcha
        }, {
            onSuccess: (response) => {
                const decoded = jwt_decode(response.data.token)
                if (!!decoded) {
                    _setAccessToken(response.data.token)

                    dispatch({
                        type: 'FORM_LOGIN_SUCCESS'
                    })

                    if (!!response.data?.rsvp?.event?.slug) {
                        const cookie = new Cookies()
                        cookie.set('_esl', response.data?.rsvp?.event?.slug, {path: '/'})                    
                    }
                    setTimeout(() => {
                        window.location.href = process.env.REACT_APP_PAGE_ACCOUNT
                    }, 1000)
                } else {
                    dispatch({
                        type: 'FORM_LOGIN_FAILED'
                    })

                    _removeAccessToken()
                }
            },
            onFailure: function(error) {
                dispatch({
                    type: 'FORM_LOGIN_FAILED'
                })

                _removeAccessToken()

                showErrorNotification(error)
            }
        })
    }
}

export function validateToken() {
    return dispatch => {
        return Backend.get('/v1/account/me', {}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'SET_ACTIVE_USER',
                    payload: {
                        user: response.data.data
                    }
                })
            },
            onFailure: function(error) {
                _recordLastPage()
                _removeAccessToken()
                window.location.href = process.env.REACT_APP_PAGE_LOGIN
            }
        })
    }
}

export function validateEventToken(event_id) {
    return dispatch => {
        return Backend.get(`/v1/events/${event_id}/me`, {}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'SET_ACTIVE_USER',
                    payload: {
                        user: response.data.data
                    }
                })
            },
            onFailure: function(error) {
                _recordLastPage()
                _removeAccessToken()
                window.location.href = process.env.REACT_APP_PAGE_LOGIN
            }
        })
    }
}

function _setAccessToken(accessToken) {
    const cookie = new Cookies()
    cookie.set('_at', accessToken, {path: '/'})
}

export function _getAccessToken() {
    const cookie = new Cookies()
    return cookie.get('_at', {path: '/'})
}

export function _removeAccessToken() {
    const cookie = new Cookies()

    cookie.set('_at', '', {path: '/'})
    cookie.remove('_at', {path: '/'})
    cookie.set('_esl', '', {path: '/'})
    cookie.remove('_esl', {path: '/'})
}

export function _recordLastPage() {
    if (_.includes([
        '/',
        '/login',
        '/forgot-password',
        '/register',
        '/unauthorized'
    ], window.location.pathname)) {
        return true
    }
    const cookie = new Cookies()
    cookie.set('_ref', window.location.href, {path: '/'})
}

export function _getRef() {
    const cookie = new Cookies()
    return !Form.empty(cookie.get('_ref', {path: '/'}))
        ? cookie.get('_ref', {path: '/'})
        : null
}

export function isAuthFor(role) {
    const decoded = jwt_decode(_getAccessToken())
    if (!!decoded) {
        if (_.isArray(role)) return !_.isEmpty(_.intersection(decoded.roles, role))

        return _.includes(decoded.roles, role)
    } else {
        return false
    }
}
