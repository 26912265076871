import React, {Component} from "react"
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"
import {bindActionCreators} from "redux"
import {_getAccessToken, _recordLastPage, _removeAccessToken, validateEventToken, validateToken} from "./AuthActions"
import {Form as FormHelper} from "../helper/Form"

const propTypes = {
    authenticated: PropTypes.bool,
}

const defaultProps = {
    authenticated: false
}

class AuthenticatedComponent extends Component {

    checkToken() {
        !FormHelper.empty(this.props.masterEvent.id) && this.props.validateEventToken(this.props.masterEvent.id)

        return _getAccessToken()
    }

    render() {
        const authenticated = this.props.authenticated || !!this.checkToken()
        if (!authenticated) {
            _recordLastPage()
            _removeAccessToken()
        }
        return (
            <div>
                {authenticated ? this.props.children : <Redirect to={{
                    pathname: process.env.PAGE_LOGIN,
                    state: {from: this.props.location}
                }}/>}
            </div>
        )
    }
}

AuthenticatedComponent.propTypes = propTypes
AuthenticatedComponent.defaultProps = defaultProps

function mapStateToProps(state) {
    return {
        authenticated: state.AuthReducer.isAuthenticated,
        activeUser: state.AuthReducer.activeUser,
        masterEvent: state.EventReducer.masterEvent
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        validateToken,
        validateEventToken
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent)
