import _ from 'lodash'
import * as Yup from 'yup'
import CryptoJS from 'crypto-js'

const salt = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET);

export const Form = {
    _objectParam: function(formData, field, value) {
        Object.keys(value).map(index => {
            if (value[index] instanceof Object) {
                Form._objectParam(formData, `${field}[${index}]`, value[index])
            }else {
                formData.append(`${field}[${index}]`, value[index])
            }

            return true
        })
    },
    fromJson: function(params) {
        const data = new FormData()

        Object.keys(params).map(function(key) {
            if (!Form.empty(params[key])) {
                if (params[key] instanceof File) {
                    data.append(key, params[key])
                } else if (params[key] instanceof Object) {
                    Form._objectParam(data, key, params[key])
                } else {
                    const encrypted =  CryptoJS.AES.encrypt(params[key].toString(), salt, {mode:CryptoJS.mode.ECB});

                    data.append(key, encrypted)
                }
            }

            return true
        })

        return data
    },
    encryptJson: function(params) {
        if (process.env.REACT_APP_ENV !== 'prod') {
            return params
        }
        const jsonString = JSON.stringify(params)
        return CryptoJS.AES.encrypt(jsonString, salt, {mode:CryptoJS.mode.ECB}).toString()
    },
    empty: function (param) {
        return !(!_.isUndefined(param) && !_.isNull(param) && param !== '')
    },
    valid: function(obj, field) {
        return obj.hasOwnProperty(field) && !Form.empty(obj[field])
    },
    value: function (param) {
        return (_.isUndefined(param) || _.isNull(param)) ? "" : param
    }
}

export const LoginFormik = {
    schema: Yup.object({
        email: Yup.string().required('NIP is required'),
        password: Yup.string().required('Password is required'),
    }),
    initialValues: function (params) {
        return {
            email: !!params && !!params.email ? params.email : '',
            password: '',
        }
    }
}

export const LOCATIONS = [{label: 'Perusahaan Anak', value: 'Perusahaan Anak'}, {label: 'Outsourcing', value: 'Outsourcing'}]
export const SEXS = [{label: 'Pria', value: 'Pria'}, {label: 'Wanita', value: 'Wanita'}]
export const EMPLOYEE_STATUSES = [{label: 'Karyawan Tetap', value: 'Karyawan Tetap'}, {label: 'Karyawan Tidak Tetap', value: 'Karyawan Tidak Tetap'}]
export const PARTICIPANT_ROLE = [{label: 'Member', value: 'member'}, {label: 'Reserve', value: 'reserve'}, {label: 'Captain', value: 'captain'}, {label: 'Manager', value: 'manager'}]
