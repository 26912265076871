export const initialState = {
    livestreamLoading: false,
    livestreams: [],
};

export default function LivestreamReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_LIVESTREAM_PROCESS":
            return {...state, livestreamLoading: true, livestreams: initialState.livestreams};
        case "FETCH_LIVESTREAM_SUCCESS":
            return {...state, livestreamLoading: false, livestreams: action.payload};
        case "FETCH_LIVESTREAM_FAILED":
            return {...state, livestreamLoading: false, livestreams: initialState.livestreams};

        default:
            return state;
    }
}
