const initialState = {
    updateIsActiveStatus: false,
    modal: {
        open: false, 
        content: null,
        header: {
            title: null
        },
        footer: null,
    }
};

export default function CommonReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_IS_ACTIVE_PROCESS':
            return {...state, updateIsActiveStatus: true};
        case 'UPDATE_IS_ACTIVE_SUCCESS':
            return {...state, updateIsActiveStatus: false};
        case 'UPDATE_IS_ACTIVE_FAILED':
            return {...state, updateIsActiveStatus: false};

        case 'MODAL_OPENED':
            return {...state, modal: {...action.payload, open: true}};
        case 'MODAL_CLOSED':
            return {...state, modal: {...initialState.modal}};
            
        case 'HEADER_TITLE_UPDATE':
            return {...state, header: {...state.header, title: action.payload}};

        default:
            return state;
    }

}