import Backend, {showErrorNotification} from "../../components/helper/Backend";
import {Form as FormHelper} from "../../components/helper/Form"

export function getEvent(event_id, module, suffixState, additionalParams) {
    return dispatch => {
        dispatch({
            type: `FETCH_EVENT${suffixState}_PROCESS`
        })
        
        let endpoint = '/v1/events'
        if (!FormHelper.empty(event_id)) endpoint = `${endpoint}/${event_id}`
        if (!FormHelper.empty(module)) endpoint = `${endpoint}/${module}`

        let params = {}
        if (!FormHelper.empty(additionalParams)) params = {...params, ...additionalParams}

        return Backend.get(endpoint, params, {
            onSuccess: function(response) {
                dispatch({
                    type: `FETCH_EVENT${suffixState}_SUCCESS`,
                    payload: response.data.data,
                    meta: response.data.meta,
                })
            },
            onFailure: function(error) {
                dispatch({
                    type: `FETCH_EVENT${suffixState}_FAILED`
                })
            }
        }).then(response => {
            return response.status === 200
        })
    }
}

export function getEventBySlug(slug) {
    return dispatch => {
        dispatch({
            type: 'FETCH_EVENT_PROCESS'
        });

        return Backend.get(`/v1/events/${slug}`, {}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'FETCH_EVENT_SUCCESS',
                    payload: response.data.data
                });
            },
            onFailure: function(error) {
                dispatch({
                    type: 'FETCH_EVENT_FAILED'
                });
                showErrorNotification(error);
            }
        }).then(response => {
            return response.status === 200;
        });
    }
}

export function getEventRelation(event_id, module, suffixState, additionalParams) {
    suffixState = FormHelper.empty(suffixState) ? '' : suffixState
    return dispatch => {
        dispatch({
            type: `FETCH_EVENT${suffixState}_PROCESS`
        })
        
        let endpoint = '/v1/events'
        if (!FormHelper.empty(event_id)) endpoint = `${endpoint}/${event_id}`
        if (!FormHelper.empty(module)) endpoint = `${endpoint}/${module}`

        let params = {}
        if (!FormHelper.empty(additionalParams)) params = {...params, ...additionalParams}

        return Backend.get(endpoint, params, {
            onSuccess: function(response) {
                dispatch({
                    type: `FETCH_EVENT${suffixState}_SUCCESS`,
                    payload: response.data.data,
                    meta: response.data.meta,
                })
            },
            onFailure: function(error) {
                dispatch({
                    type: `FETCH_EVENT${suffixState}_FAILED`
                })
            }
        }).then(response => {
            return response.status === 200
        })
    }
}

export function postEventRelation(event_id, module, suffixState, additionalParams) {
    suffixState = FormHelper.empty(suffixState) ? '' : suffixState
    return dispatch => {
        dispatch({
            type: `POST_EVENT${suffixState}_PROCESS`
        })
        
        let endpoint = '/v1/events'
        if (!FormHelper.empty(event_id)) endpoint = `${endpoint}/${event_id}`
        if (!FormHelper.empty(module)) endpoint = `${endpoint}/${module}`

        let params = {}
        if (!FormHelper.empty(additionalParams)) params = {...params, ...additionalParams}

        return Backend.post(endpoint, params, {
            onSuccess: function(response) {
                dispatch({
                    type: `POST_EVENT${suffixState}_SUCCESS`,
                    payload: response.data.data,
                })
            },
            onFailure: function(error) {
                dispatch({
                    type: `POST_EVENT${suffixState}_FAILED`
                })
            }
        }).then(response => {
            return response.status === 201 ? response.data.data : response.response.data
        })
    }
}

export function getEventBanner(event_id, params, limit) {
    return dispatch => {
        dispatch({
            type: 'FETCH_BANNER_PROCESS'
        });

        return Backend.get(`/v1/events/${event_id}/banner`, {...params, limit}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'FETCH_BANNER_SUCCESS',
                    payload: response.data.data
                });
            },
            onFailure: function(error) {
                dispatch({
                    type: 'FETCH_BANNER_FAILED'
                });
            }
        }).then(response => {
            return response.status === 200;
        });
    }
}

export function getEventMenu(event_id, limit) {
    return dispatch => {
        dispatch({
            type: 'FETCH_MENU_PROCESS'
        });

        return Backend.get(`/v1/events/${event_id}/menu`, {limit}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'FETCH_MENU_SUCCESS',
                    payload: response.data.data
                });
            },
            onFailure: function(error) {
                dispatch({
                    type: 'FETCH_MENU_FAILED'
                });
            }
        }).then(response => {
            return response.status === 200;
        });
    }
}

export function viewEventPage(event_id, slug) {
    return dispatch => {
        dispatch({
            type: 'VIEW_PAGE_PROCESS'
        });

        return Backend.get(`/v1/events/${event_id}/pages/${slug}`, {}, {
            onSuccess: function(response) {
                dispatch({
                    type: 'VIEW_PAGE_SUCCESS',
                    payload: response.data.data
                });
            },
            onFailure: function(error) {
                dispatch({
                    type: 'VIEW_PAGE_FAILED'
                });
            }
        }).then(response => {
            return response.status === 200;
        });
    }
}
