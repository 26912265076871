import _ from 'lodash'

export const initialState = {
    boothsLoading: false,
    booths: [],
    booth: {
        id: null,
        title: null,
        slug: null,
        startTime: null,
        endTime: null,
        dueDate: null,
        rating: null,
        photo_url: null,
        created_at: null,
    },
    metaBooth: {count: 0},
    activityLoading: false,
    activities: [],
    activity: {
        id: null,
        title: null,
        photo_url: null,
        created_at: null,
    },
    metaActivity: {count: 0},
    ratingLoading: false,
    ratings: [],
    rating: {
        id: null,
        title: null,
        photo_url: null,
        created_at: null,
    },
    metaRating: {count: 0},
}

export default function BoothReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_BOOTH_PROCESS":
            return {...state, boothsLoading: true, booths: initialState.booths}
        case "FETCH_BOOTH_SUCCESS":
            let boothsResponse = {...state, boothsLoading: false}
            return _.isArray(action.payload)
                ? {...boothsResponse, booths: action.payload, metaBooth: action.meta}
                : {...boothsResponse, booth: action.payload}
        case "FETCH_BOOTH_FAILED":
            return {...state, boothsLoading: false, booths: initialState.booths}

        case "FETCH_BOOTH_ACTIVITY_PROCESS":
            return {...state, activityLoading: true, activities: initialState.activities}
        case "FETCH_BOOTH_ACTIVITY_SUCCESS":
            let activitiesResponse = {...state, activityLoading: false}
            return _.isArray(action.payload)
                ? {...activitiesResponse, activities: action.payload, metaActivity: action.meta}
                : {...activitiesResponse, activity: action.payload}
        case "FETCH_BOOTH_ACTIVITY_FAILED":
            return {...state, activityLoading: false, activities: initialState.activities}

        case "FETCH_BOOTH_RATING_PROCESS":
            return {...state, ratingLoading: true, ratings: initialState.ratings}
        case "FETCH_BOOTH_RATING_SUCCESS":
            let ratingsResponse = {...state, ratingLoading: false}
            return _.isArray(action.payload)
                ? {...ratingsResponse, ratings: action.payload, metaRating: action.meta}
                : {...ratingsResponse, rating: action.payload}
        case "FETCH_BOOTH_RATING_FAILED":
            return {...state, ratingLoading: false, ratings: initialState.ratings}

        default:
            return state
    }
}
