import _ from 'lodash'

export const initialState = {
    formsLoading: false,
    forms: [],
    form: {
        id: null,
        title: null,
        slug: null,
        content: null,
        dueDate: null,
        background_url: null,
        logo_url: null,
        created_at: null,
    }
}

export default function FormReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_FORM_PROCESS":
            return {...state, formsLoading: true, forms: initialState.forms}
        case "FETCH_FORM_SUCCESS":
            let formsResponse = {...state, formsLoading: false}
            return _.isArray(action.payload) ? {...formsResponse, forms: action.payload} : {...formsResponse, form: action.payload}
        case "FETCH_FORM_FAILED":
            return {...state, formsLoading: false, forms: initialState.forms}

        default:
            return state
    }
}
