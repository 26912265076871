import React, {Component} from "react";
import {connect} from "react-redux";
import _ from 'lodash';
import {Modal, ModalBody} from "reactstrap";
import {Form as FormHelper} from "../helper/Form"

class ModalRedux extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false, 
            content: null,
            header: null,
            footer: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.modal, prevProps.modal)) {
            this.setState({...this.props.modal})
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.open} centered={true}>
                {!FormHelper.empty(this.state.header) && this.state.header}
                <ModalBody>{this.state.content}</ModalBody>
                {!FormHelper.empty(this.state.footer) && this.state.footer}
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.CommonReducer.modal,
    };
}

export default connect(mapStateToProps, null)(ModalRedux);
