import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Cookies} from 'react-cookie'
import _ from 'lodash'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {connect} from 'react-redux'
import styled from 'styled-components'
import Popup from 'react-popup'
import {ToastContainer} from 'react-toastify'
import {asyncComponent} from './components/helper/AsyncFunc'
import AuthenticatedComponent from './components/auth/AuthenticatedComponent'
import {bindActionCreators} from "redux"
import {getEventBySlug} from "./containers/event/EventActions"
import {Form as FormHelper} from "./components/helper/Form"
import ModalRedux from './components/common/ModalRedux'

const RestrictedRoute = ({component: Component, ...rest}) => (
        <Route
            {...rest}
            render={props => <AuthenticatedComponent><Component {...props} /></AuthenticatedComponent>}
        />
)

class PublicRoutes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            masterEvent: props.masterEvent
        }

        this.renderStyle = this.renderStyle.bind(this)
    }

    componentDidMount() {
        let subdomain = process.env.REACT_APP_ENV === 'prod'
            ? window.location.hostname.split('.').slice(0, -2).join('.')
            : process.env.REACT_APP_DEFAULT_EVENT

        if (process.env.REACT_APP_TESTING_EVENT) {
            subdomain = process.env.REACT_APP_TESTING_EVENT
        }

        if (!['/login', '/register', '/reset-password', '/forgot-password'].includes(window.location.pathname)) {
            const cookie = new Cookies()
            const esl = cookie.get('_esl', {path: '/'})
            if (!FormHelper.empty(esl)) {
                subdomain = esl
            }
        }

        setTimeout(() => {
            this.props.getEventBySlug(subdomain)
        }, 400)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.masterEvent, prevProps.masterEvent)) {
            this.setState({masterEvent: this.props.masterEvent})
        }
    }

    renderStyle() {
        if (FormHelper.empty(this.state.masterEvent)) return;

        const event = this.state.masterEvent

        const fontFile = FormHelper.empty(event.font_url)
            ? ''
            : `
                @font-face {
                    font-family: "CustomFont";
                    src: url("${event.font_url}");
                    font-weight: normal;
                    font-style: normal;
                }
            `
        const fontExtraLarge = FormHelper.empty(event.font_extra_large)
            ? ''
            : `.text-size-extralarge {font-size: ${event.font_extra_large}px !important;}`
        const fontLarge = FormHelper.empty(event.font_large)
            ? ''
            : `.text-size-large {font-size: ${event.font_large}px !important;}`
        const fontMedium = FormHelper.empty(event.font_medium)
            ? ''
            : `.text-size-medium {font-size: ${event.font_medium}px !important;}`
        const fontNormal = FormHelper.empty(event.font_small)
            ? ''
            : `.text-size-normal {font-size: ${event.font_small}px !important;}`
        const fontSmall = FormHelper.empty(event.font_extra_small)
            ? ''
            : `.text-size-small {font-size: ${event.font_extra_small}px !important;}`

        const colorPrimary = FormHelper.empty(event.color_primary)
            ? ''
            : `.btn-primary {background-color: #${event.color_primary} !important;}.bg-primary {background-color: #${event.color_primary} !important;}.alert-primary {background-color: #${event.color_primary} !important;}.text-primary {color: #${event.color_primary} !important;}`
        const colorSecondary = FormHelper.empty(event.color_secondary)
            ? ''
            : `.btn-secondary {background-color: #${event.color_secondary} !important;}.bg-secondary {background-color: #${event.color_secondary} !important;}.alert-secondary {background-color: #${event.color_secondary} !important;}.text-secondary {color: #${event.color_secondary} !important;}`
        const colorSuccess = FormHelper.empty(event.color_success)
            ? ''
            : `.btn-success {background-color: #${event.color_success} !important;}.bg-success {background-color: #${event.color_success} !important;}.alert-success {background-color: #${event.color_success} !important;}.text-success {color: #${event.color_success} !important;}`
        const colorDanger = FormHelper.empty(event.color_danger)
            ? ''
            : `.btn-danger {background-color: #${event.color_danger} !important;}.bg-danger {background-color: #${event.color_danger} !important;}.alert-danger {background-color: #${event.color_danger} !important;}.text-danger {color: #${event.color_danger} !important;}`
        const colorWarning = FormHelper.empty(event.color_warning)
            ? ''
            : `.btn-warning {background-color: #${event.color_warning} !important;}.bg-warning {background-color: #${event.color_warning} !important;}.alert-warning {background-color: #${event.color_warning} !important;}.text-warning {color: #${event.color_warning} !important;}`
        const colorInfo = FormHelper.empty(event.color_info)
            ? ''
            : `.btn-info {background-color: #${event.color_info} !important;}.bg-info {background-color: #${event.color_info} !important;}.alert-info {background-color: #${event.color_info} !important;}.text-info {color: #${event.color_info} !important;}`

        return styled.div`
            ${fontFile}
            ${fontExtraLarge}
            ${fontLarge}
            ${fontMedium}
            ${fontNormal}
            ${fontSmall}
            ${colorPrimary}
            ${colorSecondary}
            ${colorSuccess}
            ${colorDanger}
            ${colorWarning}
            ${colorInfo}
        `
    }

    render() {
        const CustomStyle =  this.renderStyle()

        return (
            <CustomStyle>
                <ConnectedRouter history={this.props.history}>
                    <ToastContainer autoClose={5000} toastClassName="container-toaster"
                                    progressClassName="container-toaster-progress-bar"/>
                    <Popup/>
                    <ModalRedux />
                    <Switch>
                        <Redirect exact from="/" to="/login"/>
                        <Route exact path={'/login'}
                            component={asyncComponent(() => import('./containers/public/pages/LoginPage'))}/>
                        <Route exact path={'/reset-password'}
                            component={asyncComponent(() => import('./containers/public/pages/ResetPasswordPage'))}/>
                        <Route exact path={'/forgot-password'}
                            component={asyncComponent(() => import('./containers/public/pages/ForgotPasswordPage'))}/>
                        <Route exact path={'/register'}
                            component={asyncComponent(() => import('./containers/public/pages/RegisterPage'))}/>
                        <Route path="/form/:formSlug/public"
                            component={asyncComponent(() => import('./containers/form/pages/FormPage'))}/>
                        <RestrictedRoute path="/account"
                            component={asyncComponent(() => import('./containers/account/pages/IndexPage'))}/>
                        <RestrictedRoute path="/profile/update"
                            component={asyncComponent(() => import('./containers/account/pages/UpdateProfilePage'))}/>
                        <RestrictedRoute path="/pic"
                            component={asyncComponent(() => import('./containers/event/pages/PicPage'))}/>
                        <RestrictedRoute path="/cabor"
                            component={asyncComponent(() => import('./containers/porseni/PorseniPage'))}/>
                        <RestrictedRoute path="/news/:newsId"
                            component={asyncComponent(() => import('./containers/news/pages/DetailPage'))}/>
                        <RestrictedRoute path="/form/:formSlug"
                            component={asyncComponent(() => import('./containers/form/pages/FormPage'))}/>
                        <RestrictedRoute path="/page/:pageSlug"
                            component={asyncComponent(() => import('./containers/page/pages/DetailPage'))}/>
                        <RestrictedRoute path="/booth"
                            component={asyncComponent(() => import('./containers/booth/BoothPage'))}/>
                        <RestrictedRoute path="/schedule"
                            component={asyncComponent(() => import('./containers/schedule/SchedulePage'))}/>
                        <RestrictedRoute path="/games"
                            component={asyncComponent(() => import('./containers/event/pages/GamesPage'))}/>
                        <RestrictedRoute path="/poll"
                            component={asyncComponent(() => import('./containers/event/pages/PollPage'))}/>
                        <RestrictedRoute path="/quiz"
                            component={asyncComponent(() => import('./containers/quiz/QuizPage'))}/>
                        <RestrictedRoute path="/merchant"
                            component={asyncComponent(() => import('./containers/merchant/MerchantPage'))}/>
                        <RestrictedRoute path="/registration/qr_code"
                            component={asyncComponent(() => import('./containers/event/pages/RegisterQRCodePage'))}/>
                        <RestrictedRoute path="/registration/access_code"
                            component={asyncComponent(() => import('./containers/event/pages/RegisterAccessCodePage'))}/>
                        <Route exact path={'/bcagobigstep'}
                            component={asyncComponent(() => import('./containers/public/pages/ReferrerPage'))}/>
                        <Route exact path={'/unauthorized'}
                            component={asyncComponent(() => import('./containers/exception/UnauthorizedPage'))}/>
                        <Route exact path={'/not-found'}
                            component={asyncComponent(() => import('./containers/exception/NotFoundPage'))}/>
                        <Route component={asyncComponent(() => import('./containers/exception/NotFoundPage'))}/>
                    </Switch>
                </ConnectedRouter>
            </CustomStyle>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEventBySlug,
    }, dispatch)
}

function mapStateToProps(state) {
    return {
        masterEvent: state.EventReducer.masterEvent,
    }
}

PublicRoutes.propTypes = {
    history: PropTypes.object
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes)
