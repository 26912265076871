import _ from 'lodash'

export const initialState = {
    merchantsLoading: false,
    merchants: [],
    merchant: {
        id: null,
    },
    merchantOrdersLoading: false,
    merchantOrders: [],
    merchantOrder: {
        id: null
    },
    merchantOrdersHistoryLoading: false,
    merchantOrdersHistory: [],
    merchantOrderHistory: {
        id: null
    },
}

export default function MerchantReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_MERCHANT_PROCESS":
            return {...state, merchantsLoading: true, merchants: initialState.merchants}
        case "FETCH_MERCHANT_SUCCESS":
            let merchantsResponse = {...state, merchantsLoading: false}
            return _.isArray(action.payload) ? {...merchantsResponse, merchants: action.payload} : {...merchantsResponse, merchant: action.payload}
        case "FETCH_MERCHANT_FAILED":
            return {...state, merchantsLoading: false, merchants: initialState.merchants}

        case "FETCH_MERCHANT_ORDER_PROCESS":
            return {...state, merchantOrdersLoading: true, merchantOrders: initialState.merchantOrders}
        case "FETCH_MERCHANT_ORDER_SUCCESS":
            let merchantOrdersResponse = {...state, merchantOrdersLoading: false}
            return _.isArray(action.payload) ? {...merchantOrdersResponse, merchantOrders: action.payload} : {...merchantOrdersResponse, merchantOrder: action.payload}
        case "FETCH_MERCHANT_ORDER_FAILED":
            return {...state, merchantOrdersLoading: false, merchantOrders: initialState.merchantOrders}

        case "FETCH_MERCHANT_ORDER_HISTORY_PROCESS":
            return {...state, merchantOrdersHistoryLoading: true, merchantOrdersHistory: initialState.merchantOrdersHistory}
        case "FETCH_MERCHANT_ORDER_HISTORY_SUCCESS":
            let merchantOrdersHistoryResponse = {...state, merchantOrdersHistoryLoading: false}
            return _.isArray(action.payload) ? {...merchantOrdersHistoryResponse, merchantOrdersHistory: action.payload} : {...merchantOrdersHistoryResponse, merchantOrderHistory: action.payload}
        case "FETCH_MERCHANT_ORDER_HISTORY_FAILED":
            return {...state, merchantOrdersHistoryLoading: false, merchantOrdersHistory: initialState.merchantOrdersHistory}

        case "SUBMIT_MERCHANT_ORDER_PROCESS":
            return {...state, merchantOrdersLoading: true, merchantOrder: initialState.merchantOrder}
        case "SUBMIT_MERCHANT_ORDER_SUCCESS":
            return {...state, merchantOrdersLoading: false, merchantOrder: action.payload}
        case "SUBMIT_MERCHANT_ORDER_FAILED":
            return {...state, merchantOrdersLoading: false, merchantOrder: initialState.merchantOrder}

        default:
            return state
    }
}
