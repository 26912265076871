import axios from 'axios';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {Form} from './Form';
import {_getAccessToken} from "../auth/AuthActions";
import CryptoJS from 'crypto-js'
import {generateUUID} from './utils'

const salt = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET);

const Backend = {
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    allowMethod: ['post', 'get', 'put', 'delete'],
    request: function (method, url, params, callback, headers) {
        if (!Backend.allowMethod.includes(method)) {
            toast('Request method not allowed');
            return;
        }
        if (!url) {
            toast('Please specify url request');
            return;
        }
        if (callback.hasOwnProperty('beforeProcess') && !!callback.beforeProcess) {
            callback.beforeProcess();
        }
        let requestHeader = {'Access-Control-Allow-Origin': window.location.origin}
        if (headers) requestHeader = {...headers, ...requestHeader}
        if (!!_getAccessToken()) requestHeader['Authorization'] = `Bearer ${_getAccessToken()}`

        return axios({
            method: method,
            url: Backend.endpoint + url,
            data: params ? params : {},
            headers: requestHeader,
        }).then(function (response) {
            if (process.env.REACT_APP_ENV === 'prod') {
                const decrypted = CryptoJS.AES.decrypt(response.data.toString(), salt, {mode:CryptoJS.mode.ECB})
                response.data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
            }

            if (callback.hasOwnProperty('onSuccess') && !!callback.onSuccess) {
                callback.onSuccess(response);
            }

            return response;
        }).catch(function (error) {
            if (process.env.REACT_APP_ENV === 'prod') {
                const decrypted = CryptoJS.AES.decrypt(error.response.data.toString(), salt, {mode:CryptoJS.mode.ECB})
                error.response.data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
            }

            if (callback.hasOwnProperty('onFailure') && !!callback.onFailure) {
                callback.onFailure(error);
            }
            return error;
        });
    },
    get: function (url, params, callback, headers) {
        const queryString = Object.keys(params).map(key => {
            if (params[key] instanceof Object) {
                return Object.keys(params[key]).map(index => `${key}[${index}]=${params[key][index]}`).join('&');
            }
            return `${key}=${params[key]}`;
        }).join('&');

        return Backend.request('get', url + '?' + queryString, {}, callback, headers)
    },
    post: function (url, params, callback, headers) {
        return Backend.request('post', url, Form.encryptJson({...params, ts: generateUUID()}), callback, {...headers, 'Content-Type': 'application/json'})
    },
    put: function (url, params, callback, headers) {
        return Backend.request('put', url, Form.encryptJson({...params, ts: generateUUID()}), callback, {...headers, 'Content-Type': 'application/json'})
    },
    delete: function (url, params, callback, headers) {
        return Backend.request('delete', url, Form.encryptJson({...params, ts: generateUUID()}), callback, headers)
    }
};

export function showErrorNotification(error) {
    if (!!error.response && !!error.response.data && !!error.response.data.payload) {
        if (_.isObject(error.response.data.payload)) {
            Object.keys(error.response.data.payload).map((field) => {
                if (_.isArray(error.response.data.payload[field])) {
                    error.response.data.payload[field].forEach(message => {
                        return toast.error(message);
                    })
                } else {
                    Object.keys(error.response.data.payload[field]).map(index => {
                        Object.keys(error.response.data.payload[field][index]).map(subfield => {
                            error.response.data.payload[field][index][subfield].forEach(message => {
                                return toast.error(`${_.capitalize(subfield)}: ${message}`);
                            })
                            return true;
                        })
                        return true;
                    })
                }
                return true;
            });
        } else {
            return toast.error(error.response.data.payload);
        }
    } else if (!!error.response && !!error.response.data && !!error.response.data.message) {
        if (_.isObject(error.response.data.message)) {
            Object.keys(error.response.data.message).map((field) => {
                return toast.error(_.upperFirst(field) + ': ' + error.response.data.message[field]);
            });
            return true;
        } else if (_.isString(error.response.data.message)) {
            toast.error(error.response.data.message);
        }
    } else {
        toast.error(error.message);
    }
}

export default Backend
