import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/variables.scss';
import './components/styles/sizing.scss';
import './components/styles/spacing.scss';
import './components/styles/fonts.scss';
import './components/styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-popup/style.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-table/react-table.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import * as serviceWorker from './serviceWorker';

library.add(fab);

if (process.env.REACT_APP_ENV === 'prod' && parseInt(process.env?.REACT_NO_INTERNET, 10) === 1) {
    // Sentry.init({
    //     dsn: "https://f75324b2708344f1824e3ccf4f37fc38@o250660.ingest.sentry.io/6103766",
    //     integrations: [new Integrations.BrowserTracing()],
    //     tracesSampleRate: 1.0,
    //   });
}

if (parseInt(process.env?.REACT_NO_INTERNET, 10) === 1) {
    ReactDOM.render((
        <App/>
    ), document.getElementById('root'));    
} else {
    ReactDOM.render((
        // <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            <App/>
        // </GoogleReCaptchaProvider>
    ), document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_ENV === 'prod') {
    // serviceWorker.register();
    serviceWorker.unregister();
} else {
    serviceWorker.unregister();
}
